import services from '../lib/shared/services';
import VorgaengeSeite from '../components/VorgaengeSeite';
import Status from '../lib/shared/status';

const isServer = typeof window === 'undefined';

const Index = (props) => {
	return (
		<VorgaengeSeite
			items={props.items}
			status={Status.VorgangActionRequired}
			countsByStatus={props.countsByStatus}
		/>
	);
};

Index.getInitialProps = async function (props) {
	const items = isServer
		? await services().vorgang.listByBankId(props.user.bankId, { inProgress: false })
		: await services().vorgang.list({ inProgress: false });

	return {
		items: items,
		...props
	};
};

export default Index;
